import React, { useState, useEffect } from 'react';
import Top from "./top.js";

const NotFound = () =>{
    

    //RENDER
    return(

        <div className="main">

            <Top/>
            <div className="notfound_box">
                <p>ERROR 404</p>
                <p>Página no encontrada</p>
            </div>

        </div>   
    )
}

export default NotFound;