import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from './images/logo_cueva.png';

const Politica = () =>{

    //Acciones: Indicar error, redirigir a comprar nuevamente.
    
    //USE NAVIGATE
    const navigate = useNavigate();
    

    //RENDER
    return(

        <div className="main">
            
            <div className="top_nav">
                <a className="skip_btn" href="javascript:window.history.back()">&#x2190; Regresar</a>
            </div>

            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>

            
            <div className="politica_box">
                    <h1>POLÍTICA DE PRIVACIDAD</h1>
                    <p className="politica_bold">“WWW.LACUEVADELOSORESORT.COM” LE DA LA BIENVENIDA, Y AGRADECIENDO SU PREFERENCIA, LE INFORMA E INVITA A TENER PRESENTE que:</p>
                    <div>
                        <p>Los términos de esta página web ““WWW.LACUEVADELOSORESORT.COM”, se refieren a “LA CUEVA DEL OSO RESORT” como un nombre meramente comercial y él único responsable es la persona física MANUEL GARCÍA GARZA, con domicilio en Maurice Ravel número 488 de la Colonia Colinas de San Jerónimo C.P. 64630, delegación Monterrey perteneciente al municipio Monterrey, Nuevo León. El término “Usted”, se refiere al usuario de los servicios que ofrece “LA CUEVA DEL OSO RESORT” a través del sitio web “WWW.LACUEVADELOSORESORT.COM” y/o a través de cualquier otro canal de comercialización utilizado actualmente o que utilice en el futuro “WWW.LACUEVADELOSORESORT.COM” para ofrecer Servicios (la/s “Plataforma/s”).</p>
                        <p>“WWW.LACUEVADELOSORESORT.COM” es una página web, cuya plataforma tecnológica permite a una gran cantidad de usuarios de servicios turísticos, contratar nuestros servicios directamente sin intermediarios, mediante la plataforma, se puede reservar los diferentes alojamientos que ofrecemos para el usuario, y que mediante ésta misma página y su plataforma tecnológica, se realizan pagos con tarjeta bancaria de débito y/o crédito por lo que contamos con “AVISOS DE PRIVACIDAD”.</p>
                        <p>Cuando Usted contrata Servicios Turísticos a través de “WWW.LACUEVADELOSORESORT.COM”, está celebrando un contrato directamente con la persona física mencionada en el primer punto de estos TÉRMINOS Y CONDICIONES, toda vez que como se ha mencionado, “LA CUEVA DEL OSO” es meramente un nombre comercial que ofrece sus Servicios Turísticos a través de nuestra Plataforma.</p>
                        <p>Al contratar Servicios Turísticos a través de “WWW.LACUEVADELOSORESORT.COM” , Usted garantiza y declara bajo formal protesta de decir verdad que: (i) es mayor de edad; (ii) acepta  expresamente los presentes Términos y Condiciones, los cuales ha comprendido en su totalidad y manifiesta su consentimiento expreso para usar medios electrónicos; (iii) posee plena capacidad para celebrar contratos; (iv) sólo utiliza la Plataforma de “WWW.LACUEVADELOSORESORT.COM”  para reservar o contratar Servicios Turísticos para Usted y/o para otra persona para quien Usted tenga autorización de actuar; (v) toda la información que Usted brinda a “LA CUEVA DEL OSO” es verídica, exacta, actual y completa.</p>
                        <p>Estas condiciones son las de carácter general que se aplican a los servicios de intermediación que le presta “WWW.LACUEVADELOSORESORT.COM” y bajo ningún aspecto reemplazan o modifican las limitaciones de responsabilidad legalmente establecidas conforme a su país de residencia.</p>
                        <p>Usted entiende y acepta expresamente que la actividad de “WWW.LACUEVADELOSORESORT.COM” se entiende como que no es responsable ni directa ni indirectamente por los daños o pérdidas que de cualquier modo Usted o quienes viajen junto a Usted puedan sufrir antes, durante o como consecuencia del destino elegido. “WWW.LACUEVADELOSORESORT.COM” no se responsabiliza por los hechos derivados de caso fortuito o fuerza mayor, incluyendo fenómenos climáticos, hechos de la naturaleza, conflictos gremiales, entre otros, que pudieran acontecer antes o durante la prestación del Servicio Turístico, y que pudieran eventualmente demorar, interrumpir o impedir la ejecución del mismo.</p>
                        <p>Antes de confirmar su solicitud de compra y previa aceptación expresa de estas Condiciones Generales (la “Solicitud de Compra”), le aconsejamos que revise directamente los términos y condiciones particulares aplicables al Servicio Turístico que desea contratar.</p>
                        <p>Recuerde tomar nota del número de Solicitud de Compra que aparecerá en la pantalla al confirmar su solicitud y/o en el mail de confirmación de compra. Ese número identifica su transacción y será indispensable para realizar cualquier aclaración y/ o gestión a través de WWW.LACUEVADELOSORESORT.COM</p>
                        <p>Cuando Usted realiza una Solicitud de Compra en “WWW.LACUEVADELOSORESORT.COM y/o confirma la solicitud de reserva con nuestro agente de venta telefónica, nos autoriza a gestionar su reserva y a realizar el/los respectivo/s cargo/s a su cuenta o tarjeta. El buen término de la gestión de la reserva y/o la confirmación de la compra, dependen del pago íntegro y oportuno del precio y/o tarifa de todos los Servicios Turísticos. Mientras no se confirme el pago total de la reserva, la Solicitud de Compra quedará en suspenso y podría ser cancelada unilateralmente por nosotros, sin responsabilidad para éste último. La confirmación definitiva de los Servicios Turísticos y precios respectivos se producirá con la emisión de el/los ticket/s electrónico/s o el envío del voucher –lo que aplique– y su respectiva facturación. Además, en las operaciones a crédito se deberán satisfacer los requisitos propios fijados por su banco emisor.  La emisión del ticket electrónico o el voucher –lo que aplique– da fe de la aceptación de los términos y condiciones del Proveedor y la celebración del contrato suscrito entre el Proveedor y la persona cuyo/s nombre/s aparece/n en dicho ticket electrónico y/o voucher.</p>
                        <p>Si la Solicitud de Compra quedara sujeta a confirmación –circunstancia que le será informada en el primer correo electrónico que reciba– los valores pagados antes de la confirmación serán recibidos bajo concepto de reserva.</p>
                        <p>Si finalmente su Solicitud de Compra fuera rechazada por cualquier motivo (por ejemplo, de manera enunciativa más no limitativa, por saldo insuficiente en su tarjeta de crédito, por falta de disponibilidad, por variación en la tarifa, etc.), WWW.LACUEVADELOSORESORT.COM procederá a informarle las opciones disponibles para el caso en que Ud. desee insistir en la compra o reserva o, a su requerimiento, le devolverá los importes abonados.</p>
                        <p>Si con posterioridad al ingreso de su Solicitud de Compra no recibe un correo electrónico con la información de compra, revise la bandeja de correo electrónico de Spam y/o ingrese a Mi Cuenta / Mi Reserva o Mis Viajes en la Plataforma con el número de Solicitud de Compra. Desde allí podrá realizar gestiones y, en caso de dudas adicionales, podrá contactarse a los teléfonos de contacto que figuran en la Plataforma.</p>
                        <p>Usted deberá ingresar todos los campos con datos exactos, completos, actuales y verídicos para poder realizar reservas válidas de Servicios Turísticos a través WWW.LACUEVADELOSORESORT.COM. Al ingresar sus datos personales en la Plataforma o declararlos vía telefónica, Usted declara que dichos datos (los “Datos Personales”) son exactos, precisos y verdaderos, y asume el compromiso de actualizar dichos Datos Personales conforme resulte necesario. WWW.LACUEVADELOSORESORT.COM y/o la persona física responsable de dicha actividad comercial, no se responsabiliza por la certeza de los Datos Personales provistos por Usted.</p>
                        <p>Usted deberá declarar una dirección de correo electrónico en apartado “Datos de Contacto” o al agente de ventas del que se trate. Ese correo es nuestra vía de contacto con Usted. Revise que el correo electrónico proporcionado como punto de contacto sea correcto y manténgase atento a la información que se le enviará a dicho correo. Se considerará que Usted tomó conocimiento oportuno de la confirmación de su Solicitud de Compra o de algún inconveniente o variación a la misma con el envío de dicha información al correo electrónico proporcionado por Usted. Tenga presente que, si el correo electrónico declarado por Ud. contiene algún error, no recibirá comunicaciones fundamentales para la gestión de su Solicitud de Compra.  De todas maneras, Ud. Puede llamar o comunicarse al correo electrónico administracion@lacuevadelosoresort.com para realizar el seguimiento y/o gestionar todas sus Solicitudes de Compra desde allí.</p>
                        <p>Usted deberá declarar sus datos fiscales exactos en el apartado “Datos de Facturación” o al agente de ventas de “LA CUEVA DEL OSO” para poder emitirle el correspondiente comprobante fiscal. WWW.LACUEVADELOSORESORT.COM no será responsable por la emisión de documentos con información incorrecta proporcionada por Usted. Usted garantiza y responde por la veracidad, exactitud, vigencia y autenticidad de los datos fiscales ingresados. La actualización de los Datos Personales mencionados en los párrafos que anteceden, no afectarán los datos fiscales de facturación proporcionados por Usted. En el caso de registrarse inconsistencias entre los datos de facturación proporcionados por Usted y los reflejados en el comprobante de venta, el cliente podrá solicitar a través de correo electrónico o vía telefónica, la corrección de la factura dentro de los 30 días desde la fecha de emisión del comprobante. Podrá recibir una factura emitida por cualquiera de las entidades detalladas en el encabezado del presente y definidas como “LA CUEVA DEL OSO RESORT”.</p>
                        <p>Cuando Usted reserva uno o más Servicios Turísticos a través de “LA CUEVA DEL OSO RESORT”, Usted debe abonar (i) el precio o tarifa fijada, (ii) los impuestos, tasas y/o percepciones fiscales aplicables al Servicio Turístico y, en caso de corresponder, (iii) el cargo de gestión por el servicio de intermediación de la empresa de que se trate (el “Cargo de Gestión”). Recuerde que “LA CUEVA DEL OSO RESORT” actúa en nombre y representación de la persona física detallada en el primer punto de estos TERMINOS Y CONDICIONES y que los valores correspondientes al precio o tarifa de los Servicios Turísticos son trasladados (endosados) al mismo. “LA CUEVA DEL OSO RESORT” le ofrece distintas modalidades para poder efectuar y abonar sus reservas de Servicios Turísticos dependiendo del Servicio a ser adquirido. Los medios y formas de pago disponibles para cada Servicio Turístico son los informados en la Plataforma o por los agentes en forma previa a ingresar su Solicitud de Compra.</p>
                        <p>RESPECTO DE LA POLITICAS DE CANCELACIÓN. – 04 días antes devolución del 100%: Para recibir un reembolso del 100 % de las tarifas de alojamiento, la cancelación debe realizarse al menos 96 horas completas antes de la hora local de llegada del día en que comienza la reserva, de lo contrario, no podrá obtenerla.</p>
                        <p>03 días antes devolución del 50%: Para recibir un reembolso del 50 % de las tarifas de alojamiento, la cancelación debe realizarse al menos 72 horas completas antes de la hora local de llegada, del día en que comienza la reserva. De lo contrario, no podrá obtenerlo.</p>
                        <p>Llegada: Si el huésped cancela con menos de 71 horas días de antelación a la hora local de entrada, no se le reembolsarán las noches no disfrutadas.</p>
                        <p>Entrada: El horario de entrada para el correspondiente alojamiento será el de las 15:00 horas locales.</p>
                        <p>Salida: El horario para el correspondiente desalojo será el de las 12:00 horas del día corriente y último reservado, por lo que, si el huésped no abandonare su hospedaje al momento de que se cumpliera tal horario o antes de dicho tiempo, se le cobrará una noche extra, y así mismo, si el huésped llega y decide dejar su alojamiento antes de tiempo, no se le reembolsarán las noches no disfrutadas.</p>
                        <p>Se hace extensivo que, al ser “LA CUEVA DEL OSO RESORT” un complejo vacacional del tipo hotelero, con las mejores instalaciones y comodidades, contamos con una serie de objetos, productos o insumos para el mayor confort de usted (siendo estos de manera enunciativa y no limitativa los siguientes: jabones de tocador, dispensadores de crema y sanitizantes, toallas, sabanas, edredones y almohadas, entre otros), al final de la estancia se realizará verificación de la o las habitaciones que se hayan ocupado por usted, y en caso de existir un faltante o alguna afectación o deterioro por uso indebido o negligente de algún producto, objeto o insumo propiedad del resort, se le efectuará el cargo económico correspondiente.</p>
                        <p>Política de Cancelación por Causas de fuerza mayor, situaciones que contemplamos válidas para una cancelación por causa de fuerza mayor: Catástrofes naturales de importancia que afecten al lugar de destino, restricciones de viaje urgentes o alertas de seguridad graves publicadas por las autoridades nacionales competentes (como, por ejemplo, un ministerio u otro organismo de la Administración Pública) en la fecha de entrada al alojamiento, enfermedad endémica declarada por una autoridad nacional o internacional fiable (como el Centro Europeo para la Prevención y el Control de las Enfermedades o la Organización Mundial de la Salud), daños graves en la propiedad o problemas de mantenimiento que afecten directamente a la capacidad del alojamiento para garantizar una estancia segura; *Quedan excluidos los posibles problemas de los que no fuésemos responsables directos. Ej.: suspensión puntual de algún suministro (p. ej.: electricidad, agua, internet) por la empresa responsable del mismo debido a algún problema/avería puntual en la zona, obligaciones civiles impuestas al alojamiento por las autoridades después de la fecha de realización de la reserva (p. ej.: clausura o cese de la actividad).</p>
                        <p>RESPECTO A LOS REEMBOLSOS. – Además de los casos anteriormente previstos y debidamente precisados en párrafos a supra líneas referentes a las cancelaciones, no procederá devolución o reembolso económico alguno por diverso concepto cualquiera que sea su naturaleza.</p> 
                    </div>
            </div>

        
        </div>   
    )
}

export default Politica;