import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './assets/estilos.css';

import Home from './assets/home.js';
import Login from './assets/login.js';
import Registro from './assets/registro.js';
import Precomprar from './assets/precomprar.js';
import Comprar from './assets/comprar.js';
import PagoExitoso from './assets/pagoexitoso.js';
import ErrorPago from './assets/errorpago.js';
import Perfil from './assets/perfil.js';
import Usuarios from './assets/usuarios.js';
import Usuario from './assets/usuario.js';
import Cabanas from './assets/cabanas.js';
import Yellowstone from './assets/yellowstone.js';
import Arteaga from './assets/arteaga.js';
import Alaska from './assets/alaska.js';
import Kodiak from './assets/kodiak.js';
import OsoNegro from './assets/osonegro.js';
import Montana from './assets/montana.js';
import Grizzly from './assets/grizzly.js';
import Agenda from './assets/agenda.js';
import ReservaExitosa from './assets/reservaexitosa.js';
import Footer from './assets/footer.js';
import Politica from './assets/politica.js';
import NotFound from './assets/404.js';


function App() {
  

  return (
    <div>
      
      
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/registro" element={<Registro/>}/>
        <Route path="/precomprar" element={<Precomprar/>}/>
        <Route path="/comprar" element={<Comprar/>}/>
        <Route path="/pagoexitoso" element={<PagoExitoso/>}/>
        <Route path="/errorpago" element={<ErrorPago/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/perfil" element={<Perfil/>}/>
        <Route path="/yellowstone" element={<Yellowstone/>}/>
        <Route path="/arteaga" element={<Arteaga/>}/>
        <Route path="/alaska" element={<Alaska/>}/>
        <Route path="/kodiak" element={<Kodiak/>}/>
        <Route path="/osonegro" element={<OsoNegro/>}/>
        <Route path="/montana" element={<Montana/>}/>
        <Route path="/grizzly" element={<Grizzly/>}/>
        <Route path="/cabanas" element={<Cabanas/>}/>
        <Route path="/reservaexitosa" element={<ReservaExitosa/>}/>
        <Route path="/politica" element={<Politica/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
  
       
      {/*
      <Routes>
        <Route path="/" element={<Usuarios/>}/>
        <Route path="/usuario/:id" element={<Usuario/>}/>
        <Route path="/agenda" element={<Agenda/>}/>
        <Route path="/politica" element={<Politica/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>*/}
      
      <Footer/>
    </div>
  );
}

export default App;
