import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebaseconfig';
import { signOut } from "firebase/auth";

import Top from "./top.js";
import oso from './images/logo_cueva_gold.png';

const Home = () =>{
    
    //Acciones: Comprobar si hay sesión iniciada, guardar UID en variable logged.

    //USE NAVIGATE
    const navigate = useNavigate();
    
    //FUNCIONES
    function test(){
        console.log(auth.currentUser);
    }

    //SET ACTIVE USER
    const [activeuser, setActiveuser] = useState(null);

    //USE EFFECT
    useEffect(() => {
        setTimeout(() => {
            if(auth.currentUser == null){
                console.log("No hay sesión activa");
                window.sessionStorage.setItem('logged', null);
               
            }else{
                setActiveuser(auth.currentUser.uid);
                window.sessionStorage.setItem('logged', auth.currentUser.uid);
            }
        }, 1000);
    }, []);

    //GO
    function goRegistro(){
        navigate('/precomprar');
    }
    

    //RENDER
    return(

        <div className="main">

            <Top/>
            <div className="cover">
                <div className="cover_box">

                    <img className="cover_logo" src={oso} alt="Oso logo"/>

                    <div className="cover_txt">
                        <h1>Membresía Oso Card</h1>
                        <p>¡Disfruta de descuentos y beneficios!</p>
                    </div>
                    
                    {
                        activeuser != null
                        ?
                        <div>
                            <button className="login_btn" onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                        </div>
                        :
                        <div className="cover_btn">
                            <button onClick={goRegistro}>VER LAS MEMBRESÍAS</button>
                        </div>
                    }
                </div>

            </div>

        </div>   
    )
}

export default Home;