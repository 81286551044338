// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCDVr4WQjqu0aoiQ-AHrPmBtbL4cY4nAIE",
    authDomain: "osocard-53bc3.firebaseapp.com",
    projectId: "osocard-53bc3",
    storageBucket: "osocard-53bc3.appspot.com",
    messagingSenderId: "17016652568",
    appId: "1:17016652568:web:660dff1a84e8d1a11cd46d",
    measurementId: "G-NZ1ZPH5VKY"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export{ auth };