import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebaseconfig';
import { signOut } from "firebase/auth";

import logo from './images/logo_cueva.png';


const Top = () =>{

    //USE NAVIGATE
    const navigate = useNavigate();

    //LOGOUT
    function logOut(){
        signOut(auth).then(() => {
            window.sessionStorage.setItem('logged', null);
            console.log("Sesión terminada");
            navigate('/');
            window.location.reload();
        }).catch((error) => {
            console.log(error);
        });
    }

    //RENDER
    return(

        <div className="top">

            <div className="logo_top" onClick={() => navigate('/')}>
                <img src={logo} alt="Logo"/>
            </div>

            {
                auth.currentUser != null
                ?
                <div className="btn_top">
                    <button onClick={logOut}>Cerrar sesión</button>
                </div> 
                :
                <div className="btn_top">
                    <button onClick={() => navigate('/login')}>Iniciar sesión</button>
                </div> 
            }

            <div className="clear"></div>

        </div>   
    )
}

export default Top;
