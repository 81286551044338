import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone'; // Importa moment desde moment-timezone
import 'moment/locale/es';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ICAL from 'ical.js';
import TopAdmin from "./topadmin.js";

moment.locale('es'); // Establece el idioma en español
const localizer = momentLocalizer(moment);

// Establecer la zona horaria por defecto
moment.tz.setDefault('UTC');


const Agenda = () =>{

    //NAVIGATE
    const navigate = useNavigate();

    //OBTENER CALENDARIO AIRBNB Y OSO CARD
    const [osoevents, setOsoevents] = useState([]);
    const [airevents, setAirevents] = useState([]);

    //OBTENER LAS RESERVAS
    const getDates = async () => {
        try {
            const osoData = await axios.get('https://osocard.renderplayz.com/server/cal_all.php');
    
            const data = osoData.data;
    
            // Registrar el contenido del archivo ICS
            console.log('Contenido del archivo ICS:', data);
    
            // Dividir los calendarios combinados por BEGIN:VCALENDAR
            const individualCalendars = data.split('BEGIN:VCALENDAR').filter(cal => cal.trim() !== '');
    
            const allEvents = [];
    
            for (let cal of individualCalendars) {
                // Reagregar la etiqueta BEGIN:VCALENDAR
                cal = 'BEGIN:VCALENDAR' + cal;
    
                // Validar el contenido del archivo ICS
                if (!cal.includes('END:VCALENDAR')) {
                    console.error('Contenido del archivo ICS no válido:', cal);
                    continue;
                }
    
                // Intentar analizar el contenido del archivo ICS
                try {
                    const jcalData = ICAL.parse(cal);
                    const comp = new ICAL.Component(jcalData);
                    const vevents = comp.getAllSubcomponents('vevent');
                    const parsedEvents = vevents.map((vevent) => ({
                        title: vevent.getFirstPropertyValue('summary'),
                        uid: vevent.getFirstPropertyValue('uid'),
                        description: vevent.getFirstPropertyValue('description'),
                        location: vevent.getFirstPropertyValue('location'),
                        start: vevent.getFirstPropertyValue('dtstart').toJSDate(),
                        end: vevent.getFirstPropertyValue('dtend').toJSDate(),
                        stamp: vevent.getFirstPropertyValue('dtstamp').toJSDate(),
                    }));
                    allEvents.push(...parsedEvents);
                } catch (e) {
                    console.error('Error al analizar un calendario ICS:', e, cal);
                }
            }
           
            setOsoevents(allEvents);
    
        } catch (error) {
            console.error('Error al cargar los archivos ICS:', error);
        }
    };
   
        
    //VER RESERVACIÓN AGENDADA
    const [res, setRes] = useState([]);

    function checkDate({start}) {
        const eventsOnDate1 = osoevents.filter(ev => ev.start === start || ev.end === start);
        const eventsOnDate2 = airevents.filter(ev => ev.start === start || ev.end === start);
        const convertedEvents1 = eventsOnDate1.map(event => ({
            ...event,
            start: moment(event.start).format('YYYY-MM-DD'),
            end: moment(event.end).format('YYYY-MM-DD'),            
        }));
        const convertedEvents2 = eventsOnDate2.map(event => ({
            ...event,
            start: moment(event.start).format('YYYY-MM-DD'),
            end: moment(event.end).format('YYYY-MM-DD'),            
        }));
        setRes(convertedEvents1.concat(convertedEvents2));
        document.getElementById('pop2').style.display = "flex";
    }


    //CERRAR POPUP2
    function closePop2(){
        var a = document.getElementById('pop2');
        a.style.display = 'none';
    }


    //USE EFFECT
    useEffect(() => {
        getDates();
     }, []);
    
   
    //RENDER
    return(

        <div className="main">


            <div className="popup" id="pop2">
                <div className="pop_box">
                    
                    <div className="pop_close" onClick={closePop2}>&times;</div>
                    <p className="pop_tt">Reservación</p>
                    {
                        res.length > 0
                        ?
                        <div className="pop_tx">
                            <p><strong>Usuario:</strong> {res[0].description ? res[0].description.split('ID:')[0].trim() : 'Sin registro'}</p>
                            <p><strong>Cabaña:</strong> {res[0].title ? res[0].title : 'Sin información'}</p>
                            <p><strong>Status:</strong> {res[0].location ? res[0].location : 'Sin información'}</p>
                            <p><strong>Check-in:</strong> {res[0].start ? res[0].start : 'Sin fecha asignada'}</p>
                            <p><strong>Check-out:</strong> {res[0].end ? res[0].end : 'Sin fecha asignada'}</p>

                        </div>
                        :
                        <div className="pop_tx">
                            <p>Sin registro</p>
                        </div>
                    }
                    
                    <div className="pop_btns">
                        {
                                res.length > 0 && res[0].description
                                ?
                                <button onClick={() => navigate('/usuario/' + res[0].description.split('ID:')[1].trim())}>Ver perfil</button>
                                :
                                null
                        }
                        
                        <button className="cancel_btn" onClick={closePop2}>Cerrar</button>
                    </div>
                    
                </div>
            </div>   
            
            <TopAdmin/>
            
            <div className="calendar_box">

                <div className="calendar_top">
                    <h1>Días reservados</h1>
                </div>
                    
                {
                    osoevents.length == 0 || osoevents.length > 0
                    ?
                    <Calendar
                        localizer={localizer}
                        events={osoevents.concat(airevents)}
                        startAccessor="start"
                        endAccessor="end"
                        views={['month']} // Mostrar solo la vista mensual
                        style={{ height: 500, width: '100%'}}
                        selectable={true} // Permite hacer clic en los espacios vacíos del calendario
                        onDoubleClickEvent={checkDate} // Cambia el color del rango seleccionado
                    />
                    :
                    <p>Por cargar...</p>
                }
            </div>

            <div className="reserv_box">
                <h2>Reservaciones</h2>

                {osoevents.map((item, index) => (
                    <Link className="user_box" to={`/usuario/${ item.description.split('ID:')[1].trim() }`}  key={item.uid} >
                        <p className="user_name">Cabaña {item.title}</p>
                        <p><strong>Usuario: </strong>{item.description.split('ID:')[0].trim()}</p>
                        <p><strong>Status: </strong>{item.location}</p>
                        <p><strong>Check-in: </strong>{item.start.toISOString().slice(0, 10)}</p>
                        <p><strong>Check-out: </strong>{item.end.toISOString().slice(0, 10)}</p>
                    </Link>
                ))}

            </div>
                
            
        </div>   
    )
}

export default Agenda;
