import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from './images/logo_cueva.png';

const PagoExitoso = () =>{

    //USE NAVIGATE
    const navigate = useNavigate();

    //Acciones: Obtener perfil con el UID de variable logged, obtener id del pago y status, guardar id del pago y status en base de datos, redireccionar a Perfil.
    
    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);

    // Obtener el valor del parámetro 'payment_id'
    const paymentId = urlParams.get('payment_id');

    // Obtener el valor del parámetro 'status'
    const status = urlParams.get('status');
    

    //OBTENER USUARIO
    const [user, setUser] = useState(JSON.parse(window.sessionStorage.getItem('userdata')));
    const [cardtype, setCardtype] = useState(window.sessionStorage.getItem('usercard'));


    //CONFIRMAR PAGO
    async function addPago(){
        try {
            var x = new Date();
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: paymentId,
                active: status,
                tarjeta: cardtype,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: 0,
                reservas: 0,
                active_fecha: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2),
                expiration_fecha: (x.getFullYear() + 1) + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2),
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            document.getElementById('msj1').style.display = "none";
            document.getElementById('msj2').style.display = "block";
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //USE EFFECT
    useEffect(() => {
       if(paymentId != null && status == 'approved'){
            addPago();
       }else{
           console.log("Sin datos de pago")
       }    
    }, []);


    //RENDER
    return(

        <div className="main">
            
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>

            
            <div className="confirm_box">

                <div id="msj1">
                    <p>Guardando datos...</p>
                </div>

                <div id="msj2">
                    <h1>PAGO EXITOSO</h1>
                    <div>
                        <button onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                    </div>
                </div>
    
            </div>

           
        </div>   
    )
}

export default PagoExitoso;