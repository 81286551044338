import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebaseconfig';
import { createUserWithEmailAndPassword } from "firebase/auth";
import axios from 'axios';

import logo from './images/logo_cueva_gold.png';

const Registro = () =>{

    //Acciones: registrar, guardar UID en variable logged, redireccionar a pagar.
    
    //USE NAVIGATE
    const navigate = useNavigate();
    
    //STATES
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [tel, setTel] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [mxstate, setMxstate] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [extra, setExtra] = useState('');
    const [idfront, setIdfront] = useState(null);
    const [idback, setIdback] = useState(null);


    async function registrar(){
        try{
            const user = await createUserWithEmailAndPassword(auth, mail, pass); 
            try {
                const formData = new FormData();
                formData.append('id', auth.currentUser.uid);
                formData.append('verificado', '');
                formData.append('nombre', name);
                formData.append('apellido', lastname);
                formData.append('correo', mail);
                formData.append('tel', tel);
                formData.append('adicional', extra);
                formData.append('direccion', address + ' ' + city + ' ' + mxstate + ' ' + 'CP: ' + zipcode);
                formData.append('pay_id', '');
                formData.append('active', '');
                formData.append('tarjeta', '');
                formData.append('kit', 'Pendiente');
                formData.append('reg1', 'Pendiente');
                formData.append('reg2', 'Pendiente');
                formData.append('reg3', 'Pendiente');
                formData.append('visitas', 0);
                formData.append('reservas', 0);
                formData.append('active_fecha', '');
                formData.append('expiration_fecha', '');
                formData.append('foto_idfront', idfront);
                formData.append('foto_idback', idback);

    
                const response = await axios.post('https://osocard.renderplayz.com/server/create.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data);
                sendMail();
                window.sessionStorage.setItem('logged', auth.currentUser.uid);
                navigate('/perfil');
    
            } catch (error) {
                console.log(error);
            }
            
        } catch(error){
            console.log(error);
            alert('Error al registrar al usuario' + '\n' + error)
        }
    }
    
    //ENVIAR CORREO
    async function sendMail(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', name + ' ' + lastname);
            formData.append('huesped_id',  auth.currentUser.uid);
            formData.append('correo',  mail);
            formData.append('asunto',  'Nuevo usuario registrado');
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_registros.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }       
    }

    //VALIDAR CORREO
    function validar(){
        var x = document.getElementById('check').checked;
        
        if(x == true && mail != '' && pass != '' && name != '' && lastname != '' && tel != '' && address != '' && extra != '' && idfront != null && idback != null){
            registrar();
        }else{
            alert('Todos los campos son obligatorios');
        }
        console.log(x)
    }

    function openPop(){
        document.getElementById('pop').style.display = "flex";
    }

    function closePop(){
        document.getElementById('pop').style.display = "none";
    }

    function aceptarTerms() {
        document.getElementById('check').checked = true;
        document.getElementById('pop').style.display = "none";
    }
    

    //RENDER
    return(

        <div className="main">


            <div className="popup" id="pop">
                <div className="pop_box2">
                    
                    <div className="warn_box">
                        <p>FAVOR DE LEER CON ATENCIÓN</p>
                    </div>
                    <p className="pop_tt">Términos y condiciones de membresías</p>
                    <div className="pop_list">
                        <ol>
                            <li>No vape, No se permite fumar y no drogas adentro y afuera de las cabañas ni en todo el complejo, estamos dentro del bosque), de ser detectado se pedirá amablemente entregarnos el producto y le será devuelto a su salida.</li>
                            <li>Prohibido hacer fogatas y el uso el carbón.</li>
                            <li>No mascotas (NO pet friendly).</li>
                            <li>Bocinas con volumen moderado para uso dentro de la cabaña (es ambiente familiar).</li>
                            <li>No calentar bombones en chimenea (tenemos un fire pit).</li>
                            <li>No Visitas.</li>
                            <li>No alimentar a los venados y borregos.</li>
                            <li>No se permite sacar las vajillas (platos, vasos etc.) fuera de las cabañas.</li>
                            <li>No se permite la entrada a más personas de la capacidad máxima de la cabaña que se rentó. Evitemos la pena de negar la entrada.</li> 
                            <li>No nos hacemos responsables de pertenencias olvidadas y/o extraviadas.</li> 
                            <li>Los niños deberán estar siempre bajo la supervisión de un adulto.</li>
                            <li>Uso de cancha de papel y tirolesa hacer la reservación (24 horas previas; no se puede usar durante o después de la lluvia) Una vez reservada NO hay cancelación y se tendrá que cubrir el costo mínimo de 3 personas.</li>
                            <li>A tu salida, la cabaña se debe entregar como se recibió a tu llegada.</li> 
                            <li>Servicio de concierge de 8:00 am a 10:30 pm, posteriormente sólo urgencias.</li> 
                            <li>Entrada Early check in 11:00 am al complejo (La cabaña se entregará a las 2:00 pm).</li> 
                            <li>Late check out a las 2:00 pm (Salir después del tiempo indicado tendrá una penalización monetaria de $500 pesos por cada 30 min. adicionales).</li>
                            <li>Costo no incluye propinas.</li> 
                            <li>Por su seguridad y la del bosque, los asadores, chimeneas y calentones serán prendidos por nuestro personal exclusivamente.</li>
                            <li>Cualquier daño a la propiedad, extravío de equipo de la cabaña y daños a los muebles deberá ser cubierto por el huésped.</li>
                            <li>Las membresías no son transferibles y sólo se aplicarán los beneficios cuando ésta sea usada por el titular o el adicional.</li>
                            <li>En el Happy hour 2x 1 y medio, aplica en cervezas seleccionadas del día, no aplica a botanas, refrescos, vinos y licores. (se requiere portar con su pulsera de miembro para que se haga valida la promoción; Membresía Gold: Sábados Membresía Black: viernes y sábados).</li>
                            <li>El acceso en membresía black al complejo sin reserva sólo aplica cuando esté presente titular o adicional, y sólo se dará acceso a máximo 6 personas incluyendo niños. (2 entradas por mes, en los días viernes y sábados).</li>
                            <li>La vigencia de las membresías es de 1 año.</li>
                            <li>Horarios del bar de 1:00 pm a 11:00 pm. (No se permite la entrada con bebidas ni alimentos).</li>
                            <li>El haber infringido cualquiera de estos términos y condiciones causará la inactivación definitiva de su membresía sin derecho a reembolso.</li>
                        </ol>
                        <p className="poplist_bold">Al aceptar, se da por entendido que “He leído los términos y condiciones, he tenido la oportunidad de aclarar mis dudas y estoy de acuerdo”.</p>
                    </div>
                    
                    <div className="pop_btns2">
                        <button onClick={aceptarTerms}>Aceptar</button>
                        <button className="cancel_btn" onClick={closePop}>Rechazar</button>
                    </div>
                    
                </div>
            </div>
            
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>

            <div className="login_box">

                <h1>Registrar cuenta</h1>
                <div className="login_fields">
                    <div className="login_field">
                        <label>Nombre(s)</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Apellidos</label>
                        <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            onChange={e => setLastname(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Teléfono</label>
                        <input
                            type="text"
                            id="tel"
                            name="tel"
                            onChange={e => setTel(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Dirección: Calle, número y colonia</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            onChange={e => setAddress(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Dirección: Municipio o ciudad</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            onChange={e => setCity(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Estado</label>
                        <select name="mxstate" id="mxstate" onChange={e => setMxstate(e.target.value)}>
                            <option value="Sin estado">Seleccione uno...</option>
                            <option value="Aguascalientes">Aguascalientes</option>
                            <option value="Baja California">Baja California</option>
                            <option value="Baja California Sur">Baja California Sur</option>
                            <option value="Campeche">Campeche</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="CDMX">Ciudad de México</option>
                            <option value="Coahuila">Coahuila</option>
                            <option value="Colima">Colima</option>
                            <option value="Durango">Durango</option>
                            <option value="Estado de México">Estado de México</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="Michoacán">Michoacán</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">San Luis Potosí</option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz">Veracruz</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                        </select>
                    </div>
                    <div className="login_minfield">
                        <label>Código postal</label>
                        <input
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            onChange={e => setZipcode(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Beneficiario adicional (nombre completo)</label>
                        <input
                            type="text"
                            id="extra"
                            name="extra"
                            onChange={e => setExtra(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Correo electrónico</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={e => setMail(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={e => setPass(e.target.value)}
                        />
                    </div>

                    <div className="login_field">
                        <label>Anexar frontal de identificación oficial:</label>
                        <input
                            type="file"
                            id="idfront"
                            name="idfront"
                            accept="image/jpeg, image/png"
                            required
                            onChange={e => setIdfront(e.target.files[0])}
                        />
                    </div>

                    <div className="login_field">
                        <label>Anexar reverso de identificación oficial:</label>
                        <input
                            type="file"
                            id="idback"
                            name="idback"
                            accept="image/jpeg, image/png"
                            required
                            onChange={e => setIdback(e.target.files[0])}
                        />
                    </div>

                    <div className="login_checkbox">
                        <input 
                            type="checkbox"
                            id="check"
                            name="check"
                        />
                        <label className="label_terms" onClick={openPop}>Leer y aceptar los términos y condiciones</label>
                    </div>

                    <button className="login_btn" onClick={validar}>Registrar</button>
                    <div className="skip_box">
                        <Link to="/login" className="skip_btn">Ya tengo una cuenta</Link>
                    </div>
                </div>
            </div>

           
           
        </div>   
    )
}

export default Registro;