import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ICalendar } from 'datebook';

import logo from './images/logo_cueva.png';

const ReservaExitosa = () =>{

    //USE NAVIGATE
    const navigate = useNavigate();

    //Acciones: Obtener perfil con el UID de variable logged, obtener id del pago y status, guardar id del pago y status en base de datos, redireccionar a Perfil.
    
    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);

    // Obtener el valor del parámetro 'payment_id'
    const paymentId = urlParams.get('payment_id');

    // Obtener el valor del parámetro 'status'
    const status = urlParams.get('status');
    

    //OBTENER USUARIO
    const [user, setUser] = useState(JSON.parse(window.sessionStorage.getItem('userdata')));
    const [userres, setUserres] = useState(JSON.parse(window.sessionStorage.getItem('reservations')));
    const [res, setRes] = useState(JSON.parse(window.sessionStorage.getItem('reservation')));


    console.log(userres);
    console.log(user);
    console.log(res);

    // Añadir nuevo evento
    function updateICS(event) {
        // Crear un nuevo objeto ICalendar para el nuevo evento
        const icalEvent = new ICalendar({
            title: event.title,
            location: event.location,
            description: event.description,
            start: new Date(event.start),
            end: new Date(event.end),
            stamp: new Date(event.stamp),
        });

        // Convertir el objeto ICalendar a texto en formato ICS
        const icsContent = icalEvent.render();
        const req = {
            icsContent: icsContent.toString(),
            id: res.uid,
            stat: 'Pagada',
            extraParam: res.title.toLowerCase().replace(/\s+/g, '')// Parámetro adicional si es necesario
        };

        console.log(req);

        // Realizar la solicitud para añadir el evento al archivo ICS en el servidor
        axios.post('https://osocard.renderplayz.com/server/date_edit.php', req)
            .then(response => {
                console.log('Evento añadido al archivo ICS en el servidor:', response.data);
                sendMailRes();
                navigate('/perfil');
            })
            .catch(error => {
                console.error('Error al añadir el evento al archivo ICS en el servidor:', error);
            });
    }


    //ENVIAR CORREO
    async function sendMailRes(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('correo',  user[0].correo);
            formData.append('asunto',  'Reservación pagada');
            formData.append('fecha_init',  res.start);
            formData.append('fecha_end',  res.end);
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_reserva.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }       
    }

    //USE EFFECT
    useEffect(() => {
       if(paymentId != null && status == 'approved'){
            updateICS(res);
       }else{
           console.log("Sin datos de pago")
       }
    }, []);


    //RENDER
    return(

        <div className="main">
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>
            
            <div className="confirm_box">

                <div id="msj1">
                    <p>Guardando datos...</p>
                </div>

                <div id="msj2">
                    <h1>PAGO DE RESERVA EXITOSO</h1>
                    <div>
                        <button onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                    </div>
                </div>
    
            </div>

           
        </div>   
    )
}

export default ReservaExitosa;