import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Top from "./top.js";

import im1 from "./images/arteaga/arteaga1.webp";
import im2 from "./images/alaska/alaska1.jpeg";
import im3 from "./images/montana/montana6.webp";
import im4 from "./images/kodiak/kodiak1.webp";
import im5 from "./images/oso_negro/oso_negro1.webp";
import im6 from "./images/grizzly/grizzly1.webp";
import im7 from "./images/yellowstone/yellowstone2.webp";


const Cabanas = () =>{

    const navigate = useNavigate();
    

    //USE EFFECT
    useEffect(() => {
        
     }, []);
    
   
    //RENDER
    return(

        <div className="main">

            <Top/>

            <div className="top_nav">
                <Link className="skip_btn" to="/perfil">&#x2190; Regresar al perfil</Link>
            </div>

            <div className="submain">
                
                <h1>Cabañas</h1>
                
                <div className="cabanas_boxes">
                    
                    <div className="cabana_box" onClick={() => navigate('/arteaga')}>
                        <div className="cabana_im">
                            <img src={im1}/>
                        </div>
                        <p>Cabaña Artega</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/alaska')}>
                        <div className="cabana_im">
                            <img src={im2}/>
                        </div>
                        <p>Cabaña Alaska</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/montana')}>
                        <div className="cabana_im">
                            <img src={im3}/>
                        </div>
                        <p>Cabaña Montana</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/kodiak')}>
                        <div className="cabana_im">
                            <img src={im4}/>
                        </div>
                        <p>Cabaña Oso Kodiak</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/osonegro')}>
                        <div className="cabana_im">
                            <img src={im5}/>
                        </div>
                        <p>Cabaña Oso Negro</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/grizzly')}>
                        <div className="cabana_im">
                            <img src={im6}/>
                        </div>
                        <p>Cabaña Oso Grizzly</p>
                    </div>
                    <div className="cabana_box" onClick={() => navigate('/yellowstone')}>
                        <div className="cabana_im">
                            <img src={im7}/>
                        </div>
                        <p>Cabaña Yellowstone</p>
                    </div>

                    <div className="clear"></div>

                </div>
            </div>
            
                
            
        </div>   
    )
}

export default Cabanas;
