import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase/firebaseconfig';
import { signInWithEmailAndPassword } from "firebase/auth";

import logo from './images/logo_cueva_gold.png';


const Login = () =>{

    //Acciones: inciar sesión, guardar UID en variable logged, redireccionar a perfil.
    
    //USE NAVIGATE
    const navigate = useNavigate();

    //FUNCIONES
    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');

    //LOG IN
    async function logIn(){
        try{
            const user = await signInWithEmailAndPassword(auth, mail, pass);
            window.sessionStorage.setItem('logged', auth.currentUser.uid);
            navigate('/perfil');
        }catch(error){
            console.log(error);
            alert("Datos incorrectos")
        }
    }

    //RENDER
    return(

        <div className="main">
           
            
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>
            
            <div className="login_box">
                
                <h1>Iniciar sesión</h1>
                <div className="login_fields">
                    <div className="login_field">
                        <label>Correo electrónico</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={e => setMail(e.target.value)}
                        />
                    </div>
                    <div className="login_field">
                        <label>Contraseña</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={e => setPass(e.target.value)}
                        />
                    </div>
                </div>

                <button className="login_btn" onClick={logIn}>Entrar</button>
                
                <div className="skip_box">
                    <Link to="/registro" className="skip_btn">Registrar una cuenta</Link>
                </div>

            </div>
        </div>   
    )
}

export default Login;