import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import logo from './images/logo_cueva.png';

const ErrorPago = () =>{

    //Acciones: Indicar error, redirigir a comprar nuevamente.
    
    //USE NAVIGATE
    const navigate = useNavigate();
    
    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);

    // Obtener el valor del parámetro 'payment_id'
    const paymentId = urlParams.get('payment_id');

    // Obtener el valor del parámetro 'status'
    const status = urlParams.get('status');

    // Mostrar los valores en la consola
    console.log('ID del pago:', paymentId);
    console.log('Estado del pago:', status);
    
    
    //CHECKSTATUS
    function checkStatus() {
        console.log('ID del pago:', paymentId);
        console.log('Estado del pago:', status);
    }

    //RENDER
    return(

        <div className="main">
            
            <div className="logo_box">
                <img className="logo_login" src={logo} alt="Logo"/>
            </div>

            
            <div className="confirm_box">

                <div>
                    <h1>ERROR EN EL PAGO</h1>
                    <div>
                        <button onClick={() => navigate('/perfil')}>Ir a mi perfil</button>
                    </div>
                </div>

            </div>

        
        </div>   
    )
}

export default ErrorPago;