import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

import logo from './images/logo_cueva_gold.png';
import logo_black from './images/oso_logo_black.png';
import logo_gold from './images/oso_logo_gold.png';
import gold_ft from './images/gold_ft.png';
import black_ft from './images/black_ft.png';


const Precomprar = () =>{

    //USE NAVIGATE
    const navigate = useNavigate();
    
    //RENDER
    return(

        <div className="main">

            <div className="logo_box2">
                <img className="logo_login2" src={logo} alt="Logo"/>
            </div>

            <div className="comprar_box">
                
                <div className="comprar_msg">
                    <h1>¡Conviértete en uno Oso VIP!</h1>
                </div>

                <div className="spec_gold">
                
                    <div className="spec_gold_color" onClick={() => navigate('/registro')}>
                        <div className="logocard_box">
                            <img className="logocard_logo" src={logo_gold} alt="Logo"/>
                        </div>
                        <h3>GOLD</h3>
                        <p className="precio_msg">$4,000 MXN</p>
                        <p className="iva_msg">IVA incluido</p>
                    </div>

                    <div className="comprar_btns">
                        <button onClick={() => navigate('/registro')}>¡LA QUIERO!</button>
                    </div>

                    <div className="spec_card_im">
                        <img src={gold_ft}/>
                    </div>  
                </div>

                <div className="spec_black">
                
                    <div className="spec_black_color" onClick={() => navigate('/registro')}>
                        <div className="logocard_box">
                            <img className="logocard_logo" src={logo_black} alt="Logo"/>
                        </div>
                        <h3>BLACK</h3>
                        <p className="precio_msg">$6,000 MXN</p>
                        <p className="iva_msg">IVA incluido</p>
                    </div>

                    <div className="comprar_btns">
                        <button onClick={() => navigate('/registro')}>¡LA QUIERO!</button>
                    </div>

                    <div className="spec_card_im">
                        <img src={black_ft}/>
                    </div> 
                </div>

                <div className="clear"></div>
            
            </div>


        </div>   
    )
}

export default Precomprar;