import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

import Top from "./top.js";
import logo_black from './images/oso_logo_black.png';
import logo_gold from './images/oso_logo_gold.png';
import gold_ft from './images/gold_ft.png';
import black_ft from './images/black_ft.png';

initMercadoPago('APP_USR-5a1deac4-0db5-47ee-ac32-d38d42ac69d1');

const Comprar = () =>{

    //Acciones: Obtener perfil con el UID de variable logged, obtener id de preferencia de pago, redireccionar a Mercado Pago.

    //STATES
    const [pref, setPref] = useState('');
    
    
    //OBTENER USUARIO
    const [user, setUser] = useState([]);
    const [cardtype, setCardtype] = useState('');

    async function getUser() {
        try {
            const response = await axios.post('https://osocard.renderplayz.com/server/call_id.php', {
                id: window.sessionStorage.getItem('logged')
            });
            console.log(response.data);
            setUser(response.data);
            window.sessionStorage.setItem('userdata', JSON.stringify(response.data));
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }
    
    //CHECKOUT
    async function buyGold() {
        
        try {
            /*const response = await axios.post('https://osocard.renderplayz.com/server/comprar_gold.php',{
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
            });*/
            const response = await axios.post('https://osocard.renderplayz.com/server/comprar_gold.php',{
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
            });
            console.log(response.data.id);
            window.sessionStorage.setItem('usercard', 'Gold');
            setTipocard('Membear card Gold: $4,000 MXN');
            setPref(response.data.id);
            document.getElementById('pop').style.display = "flex";
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

     //CHECKOUT
     async function buyBlack() {
        try {
            /*const response = await axios.post('https://osocard.renderplayz.com/server/comprar_black.php',{
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
            });*/
            const response = await axios.post('https://osocard.renderplayz.com/server/comprar_black.php',{
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
            });
            console.log(response.data.id);
            window.sessionStorage.setItem('usercard', 'Black');
            setTipocard('Membear card Black: $6,000 MXN');
            setPref(response.data.id);
            document.getElementById('pop').style.display = "flex";
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //CERRAR POPUP3
    function closePop(){
        var a = document.getElementById('pop');
        a.style.display = 'none';
    }

    //TIPO DE TARJETA
    const [tipocard, setTipocard] = useState('');

    //USE EFFECT
    useEffect(() => {
        getUser();
    }, []);

    //RENDER
    return(

        <div className="main">

            <div className="popup" id="pop">
                <div className="pop_box">
                    
                    <div className="pop_close" onClick={closePop}>&times;</div>
                    <p className="pop_tt">Procesar pago</p>
                    <div className="pop_tx">
                        <p className="pop_ty">{tipocard}</p>
                        <p>Será redireccionado a Mercado Pago. Por favor, no cierres las ventanas hasta que el sistema te redireccione automáticamente.</p>
                    </div>
                    <div className="mercado_box2">
                    {
                        pref == ''
                        ?
                        <p>Cargando...</p>
                        :
                        <div id="seccion_comprar">
                            <Wallet initialization={{ preferenceId: pref }} customization={{ texts:{ valueProp: 'smart_option'}}} />
                        </div>
                    }
                    <div className="skip_box">
                        <Link to="/perfil" className="skip_btn">Cancelar Operación</Link>
                    </div>
                </div>
                    
                </div>
            </div>
            
            <Top/>
            <div className="comprar_box">
                
                <div className="comprar_msg">
                    <h1>Hola, {user.length > 0 ? user[0].nombre : "Usuario"}</h1>
                    <p>Elige tu Oso Card</p>
                </div>

                <div className="spec_gold">
                
                    <div className="spec_gold_color" onClick={buyGold}>
                        <div className="logocard_box">
                            <img className="logocard_logo" src={logo_gold} alt="Logo"/>
                        </div>
                        <h3>GOLD</h3>
                        <p className="precio_msg">$4,000 MXN</p>
                        <p className="iva_msg">IVA incluido</p>
                    </div> 
                    <div className="spec_card_im">
                        <img src={gold_ft}/>
                    </div>   
                </div>

                <div className="spec_black">
                
                    <div className="spec_black_color"  onClick={buyBlack}>
                        <div className="logocard_box">
                            <img className="logocard_logo" src={logo_black} alt="Logo"/>
                        </div>
                        <h3>BLACK</h3>
                        <p className="precio_msg">$6,000 MXN</p>
                        <p className="iva_msg">IVA incluido</p>
                    </div>
                    <div className="spec_card_im">
                        <img src={black_ft}/>
                    </div> 
                </div>

                <div className="clear"></div>
            
            </div>


        </div>   
    )
}

export default Comprar;