import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import axios from 'axios';
import moment from 'moment-timezone'; // Importa moment desde moment-timezone
import 'moment/locale/es';
import ICAL from 'ical.js';
import { ICalendar } from 'datebook';

import TopAdmin from "./topadmin.js";
import logo_black from './images/logo_cueva_black.png';
import logo_gold from './images/logo_cueva_gold.png';
import card_black from './images/card_oso_black.png';
import card_gold from './images/card_oso_gold.png';

moment.locale('es'); // Establece el idioma en español

//Establecer la zona horaria por defecto
moment.tz.setDefault('UTC');


const Usuario = () =>{

    //Acciones: obtener ID del usuario de la URL, obtener perfil del usuario, añadir visita, añadir entrega de kit, añadir pago manual.
    
    //FUNCIONES
    const u = window.location.pathname;
    var res = u.substr(9);
    console.log(u);
    console.log(res);

    //USE NAVIGATE
    const navigate = useNavigate();
    
    //DIRECCIÓN
    var site = "https://adminosocard.renderplayz.com/usuario/"

    //QR CODE
    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: "Oso Card",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    //QR CODE IMAGEN
    function saveCard(){
        const targetElement = document.getElementById("card_box"); //select HTML elements to capture
        html2canvas(targetElement).then((canvas) => {
            const link = document.createElement("a");
            link.download = "oso_card.png"; //Set the image file name to download
            link.href = canvas.toDataURL(); //set canvas image data as download URL
            link.click(); //trigger download by clicking on imaginary link
        });
    }

    //OBTENER USUARIO
    const [user, setUser] = useState([]);
 
    async function getUser() {
       try {
           const response = await axios.post('https://osocard.renderplayz.com/server/call_id.php', {
               id: res
           });
           console.log(response.data);
           setUser(response.data);
           console.log(user);
       } catch (error) {
           console.log('error:' + ' ' +  error);
       }
    }

    //OBTENER RESERVAS
    const [reservas, setReservas] = useState([]);
    const [reserva, setReserva] = useState([]);

    //END DATE CHECADOR
    var date_ext = new Date();
    console.log(date_ext)
    
    //OBTENER LAS RESERVAS
    const getDates = async () => {
        try {
            const osoData = await axios.get('https://osocard.renderplayz.com/server/cal_all.php');
    
            const data = osoData.data;
    
            // Registrar el contenido del archivo ICS
            console.log('Contenido del archivo ICS:', data);
    
            // Dividir los calendarios combinados por BEGIN:VCALENDAR
            const individualCalendars = data.split('BEGIN:VCALENDAR').filter(cal => cal.trim() !== '');
    
            const allEvents = [];
    
            for (let cal of individualCalendars) {
                // Reagregar la etiqueta BEGIN:VCALENDAR
                cal = 'BEGIN:VCALENDAR' + cal;
    
                // Validar el contenido del archivo ICS
                if (!cal.includes('END:VCALENDAR')) {
                    console.error('Contenido del archivo ICS no válido:', cal);
                    continue;
                }
    
                // Intentar analizar el contenido del archivo ICS
                try {
                    const jcalData = ICAL.parse(cal);
                    const comp = new ICAL.Component(jcalData);
                    const vevents = comp.getAllSubcomponents('vevent');
                    const parsedEvents = vevents.map((vevent) => ({
                        title: vevent.getFirstPropertyValue('summary'),
                        uid: vevent.getFirstPropertyValue('uid'),
                        description: vevent.getFirstPropertyValue('description'),
                        location: vevent.getFirstPropertyValue('location'),
                        start: vevent.getFirstPropertyValue('dtstart').toJSDate(),
                        end: vevent.getFirstPropertyValue('dtend').toJSDate(),
                        stamp: vevent.getFirstPropertyValue('dtstamp').toJSDate(),
                    }));
                    allEvents.push(...parsedEvents);
                } catch (e) {
                    console.error('Error al analizar un calendario ICS:', e, cal);
                }
            }
    
            const filteredEvents = allEvents.filter(event => {
                return event.description.includes("ID:" + user[0].id) && event.end > date_ext;
            });
            setReservas(allEvents);
            setReserva(filteredEvents);
            console.log(reserva);
            console.log(reservas);
    
        } catch (error) {
            console.error('Error al cargar los archivos ICS:', error);
        }
    };
    

    //GUARDAR RESERVA
    function updateICS() {
        // Crear un nuevo objeto ICalendar para el nuevo evento
        const icalEvent = new ICalendar({
            title: reserva[0].title,
            location: reserva[0].location,
            description: reserva[0].description,
            start: new Date(reserva[0].start),
            end: new Date(reserva[0].end),
            stamp: new Date(reserva[0].stamp),
        });

        // Convertir el objeto ICalendar a texto en formato ICS
        const icsContent = icalEvent.render();
        const req = {
            icsContent: icsContent.toString(),
            id: reserva[0].uid,
            stat: 'Aceptada',
            extraParam: reserva[0].title.toLowerCase().replace(/\s+/g, '')// Parámetro adicional si es necesario
        };

        console.log(req);

        // Realizar la solicitud para añadir el evento al archivo ICS en el servidor
        axios.post('https://osocard.renderplayz.com/server/date_edit.php', req)
            .then(response => {
                console.log('Evento añadido al archivo ICS en el servidor:', response.data);
                sendMailRes();
                window.location.reload();
            })
            .catch(error => {
                console.error('Error al añadir el evento al archivo ICS en el servidor:', error);
            });
    }

    //CANCELAR RESERVA
    function cancelICS() {

        const req = {
            id: reserva[0].uid,
            extraParam: reserva[0].title.toLowerCase().replace(/\s+/g, '')// Parámetro adicional si es necesario
        };
    
        // Realizar la solicitud para guardar el archivo ICS en el servidor
        axios.post('https://osocard.renderplayz.com/server/date_delete.php', req)
            .then(response => {
                console.log('Archivo ICS guardado en el servidor:', response.data);
                sendMailCancel();
                window.location.reload();
            })
            .catch(error => {
                console.error('Error al guardar el archivo ICS en el servidor:', error);
            });
    }

    //ENVIAR CORREO
    async function sendMailRes(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('correo',  user[0].correo);
            formData.append('asunto',  'Reservación aceptada');
            formData.append('fecha_init',  reserva[0].start);
            formData.append('fecha_end',  reserva[0].end);
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_reserva.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }       
    }

    //ENVIAR CORREO
    async function sendMailCancel(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('correo',  user[0].correo);
            formData.append('asunto',  'Reservación cancelada');
            formData.append('fecha_init',  reserva[0].start);
            formData.append('fecha_end',  reserva[0].end);
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_reserva.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }       
    }
    

    //AÑADIR VISITA
    async function addReserv() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: parseInt(user[0].reservas) + 1,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //AÑADIR VISITA
    async function addVisita() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: parseInt(user[0].visitas) + 1,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //AÑADIR VISITA
    async function addKit() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: 'Entregado',
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //AÑADIR REGALO 1
    async function addReg1() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: 'Entregado',
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //AÑADIR REGALO 2
    async function addReg2() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: 'Entregado',
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //AÑADIR REGALO 3
    async function addReg3() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: user[0].verificado,
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: 'Entregado',
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //VERIFICAR
    async function verifyUser() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: 'Si',
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            sendMail();
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //DESVERIFICAR
    async function unverifyUser() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: '',
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

     //DESVERIFICAR
     async function rejectUser() {
        try {
            const response = await axios.put('https://osocard.renderplayz.com/server/edit.php', {
                id: user[0].id,
                verificado: 'No',
                nombre: user[0].nombre,
                apellido: user[0].apellido,
                correo: user[0].correo,
                tel: user[0].tel,
                direccion: user[0].direccion,
                adicional: user[0].adicional,
                pay_id: user[0].pay_id,
                active: user[0].active,
                tarjeta: user[0].tarjeta,
                kit: user[0].kit,
                reg1: user[0].reg1,
                reg2: user[0].reg2,
                reg3: user[0].reg3,
                visitas: user[0].visitas,
                reservas: user[0].reservas,
                active_fecha: user[0].active_fecha,
                expiration_fecha: user[0].expiration_fecha,
                idfront: user[0].idfront,
                idback: user[0].idback
            });
            console.log(response.data);
            window.location.reload();
        } catch (error) {
            console.log('error:' + ' ' +  error);
        }
    }

    //ENVIAR CORREO
    async function sendMail(){
        try {
            const formData = new FormData();
              
            formData.append('huesped', user[0].nombre + ' ' + user[0].apellido);
            formData.append('huesped_id', user[0].id);
            formData.append('asunto',  'Usuario verificado');
            formData.append('correo',  user[0].correo);
            
            const response = await axios.post('https://osocard.renderplayz.com/server/mail_registros.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);  
        } catch (error) {
            console.log(error);
        }       
    }

    //OPEN POPUP
    function openPop(){
        
        if(reserva[0].title == "Yellowstone"){
            calcYellowstone();
        }
        else if(reserva[0].title == "Kodiak"){
            calcKodiak();
        }
        else if(reserva[0].title == "Oso Negro"){
            calcNegro();
        }
        else if(reserva[0].title == "Grizzly"){
            calcGrizzly();
        }
        else if(reserva[0].title == "Montana"){
            calcMontana();
        }
        else if(reserva[0].title == "Alaska"){
            calcAlaska();
        }
        else if(reserva[0].title == "Arteaga"){
            calcArteaga();
        }
        else{
            setPrevprecio(0);
        }

        document.getElementById('pop').style.display = "flex";
    }

    //CERRAR POPUP1
    function closePop(a){
        document.getElementById(a).style.display = "none";
    }

    //CANCELAR RESERVA
    function openCancel(a){
        document.getElementById(a).style.display = "flex";
    }

    //VISUALIZAR COSTOS
    const [prevprecio, setPrevprecio] = useState(0);
    
    //CALCULAR YELLOWSTONE
    function calcYellowstone(){
        var x = 10800;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR KODIAK
    function calcKodiak(){
        var x = 8800;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR OSO NEGRO
    function calcNegro(){
        var x = 7500;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR GRIZZLY
    function calcGrizzly(){
        var x = 5600;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR MONTANA
    function calcMontana(){
        var x = 5600;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR MONTANA
    function calcAlaska(){
        var x = 6800;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }

    //CALCULAR ARTEAGA
    function calcArteaga(){
        var x = 5600;

        if(user[0].tarjeta == "Gold" && user[0].reservas == 2){
            setPrevprecio(x - (x * .10));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 5){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Gold" && user[0].reservas == 9){
            setPrevprecio(x - (x * .25));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 2){
            setPrevprecio(x - (x * .15));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 5){
            setPrevprecio(x - (x * .20));
        }
        else if(user[0].tarjeta == "Black" && user[0].reservas == 9){
            setPrevprecio(x - (x * .30));
        }
        else{
            setPrevprecio(x);
        }
    }


    //USE EFFECT
    useEffect(() => {
        getUser();
        //getDates();
    }, []);

    //USE EFFECT
    useEffect(() => {
       if(user.length > 0){
           getDates();
       }else{
           console.log("No hay usuario registrado");
       }
    }, [user]);

    
    var date = new Date();
    var date_ex = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    console.log(date_ex)


    //RENDER
    return(

        <div className="main">

            <div className="popup" id="pop">
                <div className="pop_box">
                    
                    <div className="pop_close" onClick={() => closePop('pop')}>&times;</div>
                    <p className="pop_tt">Reservación</p>
                    {
                        reserva.length > 0
                        ?
                        <div className="pop_tx">
                            <p><strong>Cabaña:</strong> {reserva[0].title}</p>
                            <p><strong>Usuario:</strong> {user[0].nombre + ' ' + user[0].apellido}</p>
                            <p><strong>Check-in:</strong> {reserva[0].start ? reserva[0].start.toISOString().slice(0, 10) : 'Sin fecha asignada'}</p>
                            <p><strong>Check-out:</strong> {reserva[0].end ? reserva[0].end.toISOString().slice(0, 10) : 'Sin fecha asignada'}</p>
                            <p><strong>Precio:</strong> { ( prevprecio * (Math.floor((reserva[0].end - reserva[0].start)/(1000 * 60 * 60 * 24))) ).toLocaleString()}</p>
                        </div>
                        :
                        <div className="pop_tx">
                            <p>Sin registro</p>
                        </div>
                    }
                    
                    <div className="pop_btns">
                        <button className="cancel_btn"  onClick={() => closePop('pop')}>Cerrar</button>
                    </div>
                    
                </div>
            </div>

            <div className="popup" id="pop2">
                <div className="pop_box">
                    
                    <div className="pop_close" onClick={()=>closePop('pop2')}>&times;</div>
                    <p className="pop_tt">Cancelar reservación</p>
                    {
                        reserva.length > 0
                        ?
                        <div className="pop_tx">
                            <p><strong>Cabaña:</strong> {reserva[0].title}</p>
                            <p><strong>Usuario:</strong> {user[0].nombre + ' ' + user[0].apellido}</p>
                            <p><strong>Check-in:</strong> {reserva[0].start ? reserva[0].start.toISOString().slice(0, 10) : 'Sin fecha asignada'}</p>
                            <p><strong>Check-out:</strong> {reserva[0].end ? reserva[0].end.toISOString().slice(0, 10) : 'Sin fecha asignada'}</p>
                        </div>
                        :
                        <div className="pop_tx">
                            <p>Sin registro</p>
                        </div>
                    }
                    
                    <div className="pop_btns3">
                        <button className="delete_btn" onClick={cancelICS}>Cancelar</button>
                        <button className="cancel_btn" onClick={()=>closePop('pop2')}>Cerrar</button>
                    </div>
                    
                </div>
            </div>

            
            <TopAdmin/>
            <div>
                <div className="top_nav">
                    <Link className="skip_btn" to="/">&#x2190; Usuarios</Link>
                </div>
                
                {
                    user.length > 0
                    ?
                    <div>

                        {
                            user[0].pay_id != '' && user[0].active == 'approved'
                            ?
                            <div>
                                <div className="act_box_admin">
                                    <div className={user[0].expiration_fecha < date_ex ? "inactive_info" : "active_info"}>
                                        <p>{user[0].expiration_fecha < date_ex ? "Vencida" : "Activa"}</p>
                                    </div>

                                    <div className="admin_maindata">
                                        <p className="admin_maindata_t">{user[0].nombre + ' ' + user[0].apellido}</p>
                                        <p className="admin_maindata_i"><strong>ID:</strong> {user[0].id}</p>
                                        <div className="verified_info">
                                            {
                                                user[0].verificado == "Si"
                                                ?
                                                <p className="verified_blue">Verificado</p>
                                                :
                                               null
                                            }
                                             {
                                                user[0].verificado == ""
                                                ?
                                                <p className="verified_gray">No verificado</p>
                                                :
                                               null
                                            }
                                            {
                                                user[0].verificado == "No"
                                                ?
                                                <p className="verified_red">Revocado</p>
                                                :
                                               null
                                            }
                                        </div>
                                    </div>
                                    <div className="act_item">
                                        <button onClick={addReserv}>Reservación</button>
                                        <p className="act_item_n">{user[0].reservas}</p>
                                        <p className="act_item_t">Reservaciones</p>
                                        
                                        <div className="act_item_b">
                                            {
                                                user[0].tarjeta == "Gold"
                                                ?
                                                <div>
                                                    {
                                                
                                                        user[0].reservas == 0 
                                                        ?
                                                        <div className="act_item_black">
                                                            <p className="act_desc">Acumula reservaciones para obtener descuentos y beneficios</p> 
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 2 
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">10% de descuento en su tercera reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 3
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 10% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 1 || user[0].reservas == 4 || user[0].reservas == 7 || user[0].reservas == 8
                                                        ? 
                                                        <div className="act_item_black">
                                                            <p className="act_desc">Acumula más reservaciones para obtener descuentos</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 5 
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">15% de descuento en tu sexta reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 6
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 15% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 9
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">25% de descuento en tu decima reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 10
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 25% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas > 10
                                                        ? 
                                                        <div className="act_item_red">
                                                            <p className="act_desc">Ya usaste todos los beneficios de tu membresía</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                </div>
                                                :
                                               null
                                            }

                                            {               
                                                user[0].tarjeta == "Black"
                                                ?
                                                <div>
                                                    {
                                                
                                                        user[0].reservas == 0 
                                                        ?
                                                        <div className="act_item_black">
                                                            <p className="act_desc">Acumula reservaciones para obtener descuentos y beneficios</p> 
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 2 
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">15% de descuento en su tercera reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 3
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 15% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 1 || user[0].reservas == 4 || user[0].reservas == 7 || user[0].reservas == 8
                                                        ? 
                                                        <div className="act_item_black">
                                                            <p className="act_desc">Acumula más reservaciones para obtener descuentos</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 5 
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">20% de descuento en tu sexta reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 6
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 20% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 9
                                                        ? 
                                                        <div className="act_item_silver">
                                                            <h3>Beneficio próximo</h3>
                                                            <p className="act_desc">30% de descuento en tu decima reservación</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas == 10
                                                        ? 
                                                        <div className="act_item_gold">
                                                            <h3>Beneficio actual</h3>
                                                            <p className="act_desc">Tienes 30% de descuento en esta reservación</p> 
                                                            <p className="act_ads">Una botella de licor de nuestra cueva</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                    {
                                                        user[0].reservas > 10
                                                        ? 
                                                        <div className="act_item_red">
                                                            <p className="act_desc">Ya usaste todos los beneficios de tu membresía</p> 
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                </div>
                                                :
                                               null
                                            }     
                                        </div>

                                       

                                        {
                                            user[0].tarjeta == "Black"
                                            ?
                                            <div className="act_subitem3">
                                                <button onClick={addVisita}>Visita</button>
                                                <p className="act_item_n">{user[0].visitas}</p>
                                                <p className="act_item_t">Visitas</p>
                                                <p><strong>Tarjeta Black</strong></p>
                                            </div>
                                            :
                                            <div className="act_subitem3">
                                                <p><strong>Tarjeta Gold</strong></p>
                                            </div>
                                        }

                                    </div>

                                    <div className="act_item">
                                        <div className="act_subitem1">
                                            <button onClick={addKit} className={user[0].kit == "Entregado" ? 'blocked' : null}>Asignar Kit</button>
                                            <p className="act_item_d">{user[0].kit}</p>
                                            <p className="act_item_t">Kit de regalo</p>
                                        </div>
                                        {
                                            user[0].reservas == 3
                                            ?
                                            <div className="act_subitem2">
                                                <button onClick={addReg1} className={user[0].reg1 == "Entregado" ? 'blocked' : null}>Botella</button>
                                                <p className="act_item_d">{user[0].reg1}</p>
                                                <p className="act_item_t">Licor de la cueva 3ra. visita</p>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            user[0].reservas == 6
                                            ?
                                            <div className="act_subitem2">
                                                <button onClick={addReg2} className={user[0].reg2 == "Entregado" ? 'blocked' : null}>Botella</button>
                                                <p className="act_item_d">{user[0].reg2}</p>
                                                <p className="act_item_t">Licor de la cueva 6ta. visita</p>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            user[0].reservas == 10
                                            ?
                                            <div className="act_subitem2">
                                                <button onClick={addReg3} className={user[0].reg3 == "Entregado" ? 'blocked' : null}>Botella</button>
                                                <p className="act_item_d">{user[0].reg3}</p>
                                                <p className="act_item_t">Licor de la cueva 10ma. visita</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="act_box">
                                    <h3 className="act_box_tt">Reservaciones</h3>
                                    {
                                        reserva.length > 0 
                                        ?
                                        <div>
                                            {
                                                reserva[0].location == "Pendiente"
                                                ?
                                                <div>
                                                    <div>
                                                        <p>Reservación {reserva[0].title}: {reserva[0].location}  de aprobación</p>
                                                        <p>{reserva[0].start.toISOString().slice(0, 10) + ' - ' + reserva[0].end.toISOString().slice(0, 10)}</p>
                                                    </div>
                                                    <p className="terms_note">Términos y condiciones aceptados por el huésped.</p>
                                                    <div className="act_box_inner_btns">
                                                        <button className="action_btn" onClick={updateICS}>Aceptar reserva</button>
                                                        <button className="deny_btn" onClick={()=>openCancel('pop2')}>Cancelar reservación</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {
                                                reserva[0].location == "Aceptada"
                                                ?
                                                <div>
                                                    <div>
                                                        <p>Reservación {reserva[0].title}: {reserva[0].location}</p>
                                                        <p>{reserva[0].start.toISOString().slice(0, 10) + ' - ' + reserva[0].end.toISOString().slice(0, 10)}</p>
                                                    </div>
                                                    <p className="terms_note">Términos y condiciones aceptados por el huésped.</p>
                                                    <div className="act_box_inner_btns">
                                                        <button className="deny_btn2" onClick={()=>openCancel('pop2')}>Cancelar reservación</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                                {
                                                reserva[0].location == "Pagada"
                                                ?
                                                <div>
                                                    <div>
                                                        <p>Reservación {reserva[0].title}: {reserva[0].location}</p>
                                                        <p>{reserva[0].start.toISOString().slice(0, 10) + ' - ' + reserva[0].end.toISOString().slice(0, 10)}</p>
                                                    </div>
                                                    <p className="terms_note">Términos y condiciones aceptados por el huésped.</p>
                                                    <div className="act_box_inner_btns">
                                                        <button className="deny_btn2" onClick={()=>openCancel('pop2')}>Cancelar reservación</button>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            <p className="detalle_txt" onClick={openPop}>Ver detalle</p> 
                                        </div>
                                        :
                                        <p>Sin reservas</p>        
                                    }
                                </div>

                                <div className="id_box">
                                    
                                <div className={user[0].tarjeta == "Gold" ? "card_box_gold" : "card_box_black"} ref={contentToPrint} id="card_box">
                                        <img className="card_im" src={user[0].tarjeta == "Gold" ? card_gold : card_black} alt="card"/>
                                        <div className={user[0].tarjeta == "Gold" ? "card_data_gold" : "card_data_black"}>
                                            <p className="card_name">{user[0].nombre + ' ' + user[0].apellido}</p>
                                            <div>
                                                <p className="card_exp">Válida hasta: {user[0].expiration_fecha}</p>
                                            </div>
                                        </div>
                                        <div className="card_lector">
                                            <div className="card_qr" >
                                                <QRCode
                                                size={256}
                                                style={{ height: "auto",  width: "100%" }}
                                                value={site + user[0].id}
                                                viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <div className="card_iso">
                                                <img src={user[0].tarjeta == "Gold" ? logo_black : logo_gold} alt="Logo"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="card_btns">
                                        <button className='action_btn' onClick={() => {handlePrint(null, () => contentToPrint.current);}}>Imprimir</button>
                                        <button className='action_btn' onClick={saveCard}>Descargar</button>
                                    </div>
                                </div>

                                <div className="act_box">
                                    <h3 className="act_box_tt">Datos</h3>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Beneficiario:</p>
                                        <p className="act_item_s">{user[0].adicional}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Correo:</p>
                                        <p className="act_item_s">{user[0].correo}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Teléfono:</p>
                                        <p className="act_item_s">{user[0].tel}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Dirección:</p>
                                        <p className="act_item_s">{user[0].direccion}</p>
                                    </div>
                                    <div className="act_box_inner">
                                        <img className="id_image" src={'https://osocard.renderplayz.com/server/' + user[0].idfront}/>
                                        <img className="id_image" src={'https://osocard.renderplayz.com/server/' + user[0].idback}/>
                                        {
                                            user[0].verificado == "Si"
                                            ?
                                            <div className="act_box_inner_btns">
                                                <button className="action_btn" onClick={unverifyUser}>Quitar verificación</button>
                                                <button className="action_btn" onClick={rejectUser}>Rechazar</button>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            user[0].verificado == ""
                                            ?
                                            <div className="act_box_inner_btns">
                                                <button className="action_btn" onClick={verifyUser}>Verificar</button>
                                                <button className="action_btn" onClick={rejectUser}>Rechazar</button>
                                            </div>
                                            :
                                            null
                                        }
                                        {
                                            user[0].verificado == "No"
                                            ?
                                            <div className="act_box_inner_btns">
                                                <button className="action_btn" onClick={verifyUser}>Verificar</button>
                                                <button className="action_btn" onClick={unverifyUser}>Quitar revocación</button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="ad_box">
                                <p className="ad_name">{user[0].nombre + ' ' + user[0].apellido}</p>
                                <p className="nosesion_msg">Aún no tiene su Oso Card</p>

                                {
                                    user[0].verificado == "No"
                                    ?
                                    <p className="reject_txt">La solicitud fue rechazada.</p>
                                    :
                                    null
                                }

                                {
                                    user[0].verificado == "Si"
                                    ? 
                                    <p className="accept_txt">La solicitud fue aprobada.</p>
                                    :
                                    null
                                }

                                {
                                    user[0].verificado == ""
                                    ?
                                    <div>
                                       <p className="wait_txt">Solicitud pendiente de aprobación.</p>
                                    </div>
                                   :
                                   null
                                }

                                <div className="act_box_inner">
                                    <h3 className="act_box_tt">Datos</h3>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Beneficiario:</p>
                                        <p className="act_item_s">{user[0].adicional}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Correo:</p>
                                        <p className="act_item_s">{user[0].correo}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Teléfono:</p>
                                        <p className="act_item_s">{user[0].tel}</p>
                                    </div>
                                    <div className="act_item_data">
                                        <p className="act_item_t">Dirección:</p>
                                        <p className="act_item_s">{user[0].direccion}</p>
                                    </div>
                                </div>
                                
                                <div className="act_box_inner">
                                    <img className="id_image" src={'https://osocard.renderplayz.com/server/' + user[0].idfront}/>
                                    <img className="id_image" src={'https://osocard.renderplayz.com/server/' + user[0].idback}/>
                                    {
                                        user[0].verificado == "Si"
                                        ?
                                        <div className="act_box_inner_btns">
                                            <button className="action_btn" onClick={unverifyUser}>Quitar verificación</button>
                                            <button className="action_btn" onClick={rejectUser}>Rechazar</button>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        user[0].verificado == ""
                                        ?
                                        <div className="act_box_inner_btns">
                                            <button className="action_btn" onClick={verifyUser}>Aprobar</button>
                                            <button className="action_btn" onClick={rejectUser}>Rechazar</button>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        user[0].verificado == "No"
                                        ?
                                        <div className="act_box_inner_btns">
                                            <button className="action_btn" onClick={verifyUser}>Verificar</button>
                                            <button className="action_btn" onClick={unverifyUser}>Quitar revocación</button>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        }

                    </div>
                    :
                    <div className="id_box">
                        <p className="nosesion_msg">No existe el usuario</p>
                    </div>
                }

            </div>

        </div>   
    )
}

export default Usuario;